import React, { useState } from "react";
import { StaticImage } from "gatsby-plugin-image";

import ButtonGhost from "../Button/ButtonGhost";
import ModalVideo from "../Modal/ModalVideo";
import play from "../../images/0.0 Repeating Modules/A Real Impact/play.svg";

const Reviews = ({ headingLevel, className }) => {
  const [videoSrc, setVideoSrc] = useState("");

  const HeadingTag = headingLevel || "h2";

  return (
    <>
      <section className={`${className || "mb-20 md:mb-32"}`}>
        <div className="container">
          <div className="grid items-center gap-y-8 md:grid-cols-12 md:gap-x-10 lg:gap-x-20">
            <div className="order-2 md:order-1 md:col-span-4 md:col-start-1">
              <HeadingTag>A Real Impact</HeadingTag>
              <p>
                Hear directly from real estate agencies and teams that are using
                Real Synch and benefiting from our real estate workflow
                automation software.
              </p>
              <ButtonGhost href="/reviews/" text="More Reviews" />
            </div>

            <div className="relative order-1 md:order-2 md:col-span-8 md:col-end-13">
              <button
                data-modal-open="modal-video"
                className="absolute inset-0 z-20 m-auto flex items-center justify-center"
                onClick={() =>
                  setVideoSrc("https://www.youtube.com/embed/beEpj7edblw")
                }
              >
                <img alt="Play" src={play} className="pulse" />
              </button>
              <StaticImage
                src="../../images/0.0 Repeating Modules/A Real Impact/A Real Impact.jpg"
                loading="lazy"
                alt={`A Real Impact.jpg`}
                width={746}
                height={544}
                className="relative z-0 w-full rounded-4xl"
              />
            </div>
          </div>
        </div>
      </section>

      <ModalVideo
        modalId="modal-video"
        autoPlay={false}
        iframe={true}
        videoSource={videoSrc}
      />
    </>
  );
};

export default Reviews;
